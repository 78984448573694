<template>
    <div>
        <!-- Breadcrumbs -->
        <v-container v-if="smAndUp">
            <v-row>
                <v-col>
                    <v-breadcrumbs
                        :items="breadCrumbs"
                        divider=">"
                    ></v-breadcrumbs>
                </v-col>
            </v-row>
        </v-container>

        <!-- レボイストとは -->
        <v-container>
            <v-row justify="center">
                <v-col cols="auto" align="center">
                    <h1><heading>レボイストとは</heading></h1>
                </v-col>
                <v-col cols="12" md="12" align="center">
                    <v-img
                        :src="imageWhatsRevoist"
                        :key="imageWhatsRevoist"
                    ></v-img>
                </v-col>
                <v-col cols="auto" align="left">
                    <div
                        class="font-weight-light"
                        :class="smAndUp ? 'text-h4 my-4 ' : 'text-h6 my-4'"
                    >
                        ひとりひとりの<br /><br />カラダの悩みに寄り添い<br /><br />楽しく一緒に人生を変えていく
                    </div>
                </v-col>
            </v-row>
        </v-container>

        <v-spacer></v-spacer>

        <v-container>
            <v-row justify="center">
                <v-col cols="5">
                    <v-img :src="imageCeo" :key="imageCeo"></v-img>
                </v-col>
                <v-col cols="7">
                    <v-row>
                        <v-col
                            cols="12"
                            :class="smAndUp ? 'text-h6' : 'text-body-1'"
                            class="font-weight-light"
                            v-html="descriptionCeo"
                            v-if="smAndUp"
                        ></v-col>
                        <v-col
                            cols="12"
                            v-html="descriptionCeoSp"
                            :class="smAndUp ? 'text-h6' : 'text-body-1'"
                            class="font-weight-light"
                        ></v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>

        <!-- レボイスト、3つのポリシー -->

        <v-container>
            <v-row justify="center">
                <v-col cols="auto" align="center">
                    <heading class="under"
                        >レボイスト、<span class="red--text">3 </span
                        >つのポリシー
                    </heading>
                </v-col>
                <v-col cols="12" align="center">
                    <div
                        class="font-weight-light"
                        :class="
                            $vuetify.breakpoint.smAndUp
                                ? 'text-h6 my-4 '
                                : 'text-body-1 my-4'
                        "
                    >
                        〜選ばれる理由〜
                    </div>
                </v-col>
                <v-row :justify="smAndUp ? 'center' : 'center'">
                    <v-col cols="4" :class="smAndUp ? 'pa-8' : 'ma-auto'">
                        <v-img
                            :key="imageOncePerAWeek"
                            :src="imageOncePerAWeek"
                            width="100%"
                            height="100%"
                        >
                        </v-img>
                    </v-col>
                    <v-col cols="4" :class="smAndUp ? 'pa-8' : 'ma-auto'">
                        <v-img
                            :key="imageBestPrice"
                            :src="imageBestPrice"
                            width="100%"
                            height="100%"
                        >
                        </v-img>
                    </v-col>
                    <v-col cols="4" :class="smAndUp ? 'pa-8' : 'ma-auto'">
                        <v-img
                            :key="imageCommunityBased"
                            :src="imageCommunityBased"
                            width="100%"
                        >
                        </v-img>
                    </v-col>
                </v-row>
            </v-row>
        </v-container>

        <v-container>
            <v-row justify="center">
                <v-col cols="12" md="4" :class="smAndUp ? 'pa-8' : 'pa-4'">
                    <heading>
                        <span class="red--text">◎</span> 週1回でOK
                    </heading>
                    <span
                        class="font-weight-light"
                        :class="smAndUp ? 'text-h6' : 'text-body-1'"
                    >
                        REVOISTのパーソナルトレーニングでは、週1回のレッスンを推奨しています。
                        今現在、運動習慣がゼロの方にとっては、まずは週1回のREVOISTのパーソナルトレーニングが生活習慣に組み込まれることで、カラダは十分変わります。
                        週1回のレッスンだから、体力的にも時間的にも経済的にも、無理なく始めることができ、運動や食事のモチベーションを上手く保つことができます。
                        週1回はREVOISTで正しいトレーニング、さらにできる方は自宅でもう1回の復習トレーニング。そんな形をおすすめしています。もちろん週2回レッスンをご希望の方は週2回受けることも可能です！
                    </span>
                </v-col>
                <v-col cols="12" md="4" :class="smAndUp ? 'pa-8' : 'pa-4'">
                    <heading
                        ><span class="red--text">◎</span> 最適な料金
                    </heading>
                    <span
                        class="font-weight-light"
                        :class="smAndUp ? 'text-h6' : 'text-body-1'"
                    >
                        現状のパーソナルトレーニング業界は高額で短期間のサービスが主流になっており、受けられる人、続けられる人はとても限られます。
                        しかし、本来のカラダづくりは健康的で無理のない生活習慣を築きあげることが基本です。
                        だからREVOISTでは、健康産業として、ひとりでも多くのひとがパーソナルトレーニングを受けやすく、そして、続けやすい料金にこだわります。テナントの出店場所を厳選し、華美な設備に費用をかけないことでコストを落としお客様に還元しています。
                    </span>
                </v-col>
                <v-col cols="12" md="4" :class="smAndUp ? 'pa-8' : 'pa-4'">
                    <heading><span class="red--text">◎</span> 地域密着</heading>
                    <span
                        class="font-weight-light"
                        :class="smAndUp ? 'text-h6' : 'text-body-1'"
                    >
                        運動習慣のない方にとっては、ジムに通うこと自体がハードルになりがちです。
                        少しでもトレーニングのハードルを下げるためには、自宅の近くにジムがあることが想像以上に重要です。
                        休日でも、仕事終わりでも、買い物ついででも、いつでも気軽に通えるよう、REVOISTは地域密着で、会員様にとってとことん通いやすい環境をつくり続けます。
                        そして、地域の人々の健康の輪を広げていくことで地域貢献していきます。
                    </span>
                </v-col>
            </v-row>
        </v-container>

        <!-- こんな方にオススメ -->
        <v-container>
            <v-row justify="center">
                <v-col cols="auto" align="center">
                    <heading class="under"
                        >こんな方に<span class="red--text"
                            >オススメ！</span
                        ></heading
                    >
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="12" md="10">
                    <who-is-recommended
                        :imageUrl="imageRecommend1"
                        lead="仕事が忙しい20～30代の働く女性"
                        desc="週一回で効果が期待できるので大丈夫です。"
                    ></who-is-recommended>
                    <who-is-recommended
                        :imageUrl="imageRecommend2"
                        lead="体系が気になりだした30代～40代男性"
                        desc="出費が多い年代ですが、適正料金で安心です。"
                        :reverse="true"
                    ></who-is-recommended>
                    <who-is-recommended
                        :imageUrl="imageRecommend3"
                        lead="他のジムに通ったけど続かなかった方"
                        desc="駅近の立地なので通いやすく継続していただけます。"
                    ></who-is-recommended>
                    <link-tag compName="PersonalTraining"
                        >パーソナルトレーニング</link-tag
                    >
                </v-col>
            </v-row>
        </v-container>

        <footer-contents></footer-contents>
    </div>
</template>

<script>
import Heading from '@/components/Heading.vue'
import LinkTag from '@/components/LinkTag.vue'
import WhoIsRecommended from '@/components/revoist/WhoIsRecommended.vue'
import FooterContents from '@/components/FooterContents.vue'

export default {
    mounted() {
        document.title = `${this.$route.meta.title} | パーソナルトレーニングジム【REVOISTレボイスト】`
    },
    components: {
        Heading,
        LinkTag,
        WhoIsRecommended,
        FooterContents,
    },
    computed: {
        breadCrumbs() {
            return [
                {
                    text: 'トップ',
                    disabled: false,
                    href: './',
                },
                {
                    text: 'レボイストとは',
                    disabled: true,
                    href: 'revoist',
                },
            ]
        },
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
        imageWhatsRevoist() {
            const path = this.$vuetify.breakpoint.smAndUp
                ? '/static/pc_top/top_revoist.png'
                : 'static/sp_top/img_top01.png'
            return path
        },
        imageCeo() {
            return '/static/pc_revoist/先生白シャツ.png'
        },
        imageOncePerAWeek() {
            return '/static/pc_top/once_per_a_week.png'
        },
        imageBestPrice() {
            return '/static/pc_top/best_price.png'
        },
        imageCommunityBased() {
            return '/static/pc_top/community_based.png'
        },
        imageRecommend1() {
            return '/static/pc_revoist/こんな方におすすめ01.png'
        },
        imageRecommend2() {
            return '/static/pc_revoist/こんな方におすすめ02.png'
        },
        imageRecommend3() {
            return '/static/pc_revoist/こんな方におすすめ03.png'
        },
        descriptionCeo() {
            return (
                '健康的で美しいカラダを手に入れるために最も効果的な方法であるパーソナルトレーニング 。' +
                'REVOIST(レボイスト）は、このパーソナルトレーニングを誰もが受けられて、続けたい人 がいつまでも続けられる、' +
                'そんなサービスへと変えることを約束します。パーソナルトレーニングの最大の特徴は、' +
                'お客様の悩みや目標に合わせてメニューがオーダーメイドであることです。 <br /><br />' +
                'だからこそ、ひとりひとりにきめ細やかに寄り添うことができ、結果として健康的で美しいカラダへと最短距離で導くことが可能になります。' +
                'そのために避けて通れないのが、生活習慣へのアプローチです。なぜなら「今のカラダ＝今の生活習慣」の結晶だからです。' +
                'パーソナルトレーニングによって、生活習慣が変われば、服が着れるようになったり、体力がついたり、' +
                '痛みがなくなったり、自然と自信がみなぎり、何事にも前向きに人生を歩めるようになります。' +
                '自分が変わり、高まっていくのを実感できれば、ずっと続けたくなる。そんなパーソナルトレーニングを私たちは提供していきます。'
            )
        },
        descriptionCeoSp() {
            return (
                'ひとりでも多くのヒトにとってパーソナルトレーニングを 受けやすく、続けやすいサービスへ' +
                '<br /><br />' +
                '<span class="text-body-2">株式会社REVOIST<br /> 代表取締役 井上 大輔</span>'
            )
        },
    },
}
</script>

<style lang="scss" scoped>
.under {
    background: linear-gradient(transparent 70%, #f6cfcf 70%);
}
</style>
